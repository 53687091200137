<template>
  <div>
    <div @click="showDialog()">
      <slot></slot>
    </div>
    <v-dialog v-model="dialog" max-width="1200" persistent>
      <v-card color="grey lighten-5">
        <v-card-title class="text-h5 grey lighten-2">
          <span>Edit Item</span>
          <v-spacer></v-spacer>
          <v-btn icon text @click.stop="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text class="pt-1" style="max-height: 550px;min-height: 350px; overflow-y: auto">
          <v-row>
            <v-col md="4">
              <v-card elevation="0" class="fill-height">
                <v-card-text class="pt-1 px-2">
                  <div class="mt-2 fill-height">
                    <v-card elevation="1" class="fill-height" outlined min-height="300">
                      <v-card-title>Preview</v-card-title>
                      <v-divider></v-divider>
                      <a :href="resourceUrl(selectedItemData.item_image)" target="_blank" v-if="selectedItemData.item_image != ''">
                        <v-img :src="resourceUrl(selectedItemData.item_image)" min-height="300" max-height="350"></v-img>
                      </a>
                    </v-card>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="blue" dark dense block @click="uploadImage()">Upload Image</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col md="8">
              <v-tabs v-model="tab">
                <v-tab>General</v-tab>
                <v-tab v-if="modulePermission('beritaacara', 'update-book-value')">Update Book Value</v-tab>
                <v-tab v-if="modulePermission('beritaacara', 'update-acquisition-value')">Update Nilai Perolehan</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card elevation="0">
                    <v-card-text class="pb-0 px-2">
                      <v-text-field v-model="selectedItemData.location_code" dense outlined hide-details disabled class="mb-2" label="Location Code"></v-text-field>
                      <v-text-field v-model="selectedItemData.bin_code" dense outlined hide-details disabled class="mb-2" label="Bin Code"></v-text-field>
                      <v-text-field v-model="selectedItemData.item_no_" dense outlined hide-details disabled class="mb-2" label="Item No."></v-text-field>
                      <v-text-field v-model="selectedItemData.item_description" dense outlined hide-details disabled class="mb-2" label="Item Description"></v-text-field>
                      <v-text-field v-model="selectedItemData.unit_of_measure" dense outlined hide-details disabled class="mb-2" label="UoM"></v-text-field>
                      <v-text-field v-model="selectedItemData.unit_price" dense outlined hide-details disabled class="mb-2" label="Unit Price"></v-text-field>
                      <v-text-field v-model="selectedItemData.weight" dense outlined hide-details disabled class="mb-2" label="Weight"></v-text-field>
                      <v-text-field v-model="selectedItemData.quantity" dense outlined hide-details :disabled="isQuantityDisabled" class="mb-2" label="Quantity"></v-text-field>
                      <v-textarea v-model="selectedItemData.description" dense outlined hide-details :disabled="isDescriptionDisabled" class="mb-2" label="Description" multi-line rows="2"></v-textarea>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-if="modulePermission('beritaacara', 'update-book-value')">
                  <v-sheet class="px-2 py-2" elevation="0">
                    <v-text-field v-model="selectedItemData.quantity" dense outlined hide-details disabled class="mb-2" label="Quantity"></v-text-field>
                    <v-text-field v-model="selectedItemData.book_value" type="number" dense outlined hide-details class="mb-2" label="Nilai Buku"></v-text-field>
                    <v-text-field v-model="total_book_value" dense outlined hide-details disabled class="mb-2" label="Total Nilai Buku"></v-text-field>
                  </v-sheet>
                </v-tab-item>
                <v-tab-item v-if="modulePermission('beritaacara', 'update-acquisition-value')">
                  <v-sheet class="px-2 py-2" elevation="0">
                    <v-text-field v-model="selectedItemData.quantity" dense outlined hide-details disabled class="mb-2" label="Quantity"></v-text-field>
                    <v-text-field v-model="selectedItemData.acquisition_value" type="number" dense outlined hide-details class="mb-2" label="Nilai Perolehan"></v-text-field>
                    <v-text-field v-model="total_acquisition_value" dense outlined hide-details disabled class="mb-2" label="Total Nilai Perolehan"></v-text-field>
                  </v-sheet>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="selectItem()">Save Item</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="light" @click.stop="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <input type="file" style="display: none" name="fileupload" id="fileupload" ref="reffileupload" accept="image/*" v-on:change="handleFileUpload()" />
  </div>
</template>

<script>
export default {
  props: {
    selectedItemData: Object,
    editItemCallback: null,
    accountingMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      search: "",
      selectedItem: null,
      tab: null,
    };
  },

  computed: {

    total_book_value: function () {
      return this.selectedItemData.quantity * this.selectedItemData.book_value
    },
    total_acquisition_value: function () {
      return this.selectedItemData.quantity * this.selectedItemData.acquisition_value
    },
    isQuantityDisabled() {
      let res = false;
      if (this.accountingMode == true)
        res = true;

      return res;
    },
    isDescriptionDisabled() {
      let res = false;
      if (this.accountingMode == true)
        res = true;

      return res;
    }

  },

  methods: {
    showDialog() {
      this.dialog = true;
    },

    selectItem() {
      if (typeof this.selectedItemData !== "undefined") {
        this.$emit("editItemCallback", this.selectedItemData);
        this.dialog = false;
      }
    },

    uploadImage() {
      this.$refs.reffileupload.click();
    },

    handleFileUpload() {
      this.fileupload = this.$refs.reffileupload.files[0];

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("fileupload", this.fileupload);
      this.$axios
        .post("beritaacara/upload-image-item", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.selectedItemData.item_image = resData.data.file_src;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },
};
</script>
